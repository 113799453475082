<template>
  <div class="moozumi-main">
    <div class="banner">
      <div class="banner-inner">
        <div class="left-wrapper">
          <div class="title">Chargeback Warning (Ethoca)</div>
          <div class="content">
            <div> When consumers use MasterCard cards for transactions, Ethoca will provide a chargeback warning
              service.
            </div>
            <div class="margin-top-20">Moozumi will promptly obtain the chargeback warning provided by Ethoca and notify
              the merchant, and if necessary, refund the transaction in which the chargeback warning occurs.
            </div>
            <div class="margin-top-20">Automated and intelligent processing significantly reduces the management burden
              for merchants.
            </div>
          </div>
        </div>
        <img class="checkout" src="../../assets/images/chargeback_warning_ethoca/chargeback_ethoca.png" width="400"/>
      </div>
    </div>
    <ConnectwiththeexpertsinPaymentOrchestration></ConnectwiththeexpertsinPaymentOrchestration>
  </div>
</template>
<script>
import ConnectwiththeexpertsinPaymentOrchestration from '../../components/ConnectwiththeexpertsinPaymentOrchestration.vue'

export default {
  components: {
    ConnectwiththeexpertsinPaymentOrchestration
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.moozumi-main {
  width: 100%;
}

.banner {
  width: 100%;
  background: url(../../assets/images/accept_customer_payment/banner.png);
  background-size: cover;
  padding: 100px 0;

  .banner-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;

    .left-wrapper {
      width: 652px;
      max-width: 100%;

      .title {
        font-family: Helvetica-Bold;
        font-size: 52px;
        color: #00277E;
        text-align: left;
        line-height: 68px;
      }

      .content {
        text-align: left;
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #061B4B;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
        margin-top: 16px;
      }

      .moozumi-buttons {
        display: flex;
        justify-content: flex-start;
        margin-top: 35px;
      }
    }
  }

  .checkout {
    max-width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .banner {
    .left-wrapper {
      padding: 0 15px;
    }

    img {
      margin-top: 20px;
    }
  }
}
</style>
